import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="transparent-bg container-fluid mt-5">
      <div className="row align-items-center text-center text-md-left">
        <div className="col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
          <div className="footer-info">
            <span className="me-3">
              2024 © Language Hero
            </span>
            <span className="me-3">
              <i className="bi bi-telephone"></i> (786) 819-8806
            </span>
            <span>
              <i className="bi bi-envelope"></i> admin@languageherollc.com
            </span>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
          <div className='redes-footer'>
            <a href="https://www.instagram.com/languagehero.llc/" target="_blank" rel="noopener noreferrer" className="me-3">
              <i className="bi bi-instagram icon-violet fs-4"></i>
            </a>
            <a href="https://www.linkedin.com/in/mariana-g-tarifa-29134b116/" target="_blank" rel="noopener noreferrer">
              <i className="bi bi-linkedin fs-4"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
