import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Header from '..//Reutilizables/Header/Header.jsx';
import Footer from '../Reutilizables/Footer/Footer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Contact.css";

function Contact() {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [nombre, setNombre] = useState('');
  const [mail, setMail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://us-central1-languagehero-32080.cloudfunctions.net/api/contact', {
        nombre,
        mail,
        telefono,
        mensaje,
      });
      if (response.status === 200) {
        setMessage('Email enviado con éxito!');
        setNombre('');
        setMail('');
        setTelefono('');
        setMensaje('');
      } else {
        setMessage('Error al enviar el email');
      }
    } catch (error) {
      console.error('Error al enviar el email:', error.response ? error.response.data : error.message);
      setMessage('Error al enviar el email');
    }
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };
  

  return (
    <div className="d-flex flex-column min-vh-100 bg-celular">
      <Header changeLanguage={changeLanguage} i18n={i18n}/>
      <main className="container flex-grow-1">
        <div className="row">
          <div className="col-md-4 rounded p-4">
            <div className="contact-info">
              <div className="d-flex align-items-center mb-5">
                <i className="bi bi-telephone-fill fs-2 me-3"></i>
                <div>
                  <p className="mb-0 fw-bold fs-4">{t('contactSubTitle1')}</p>
                  <p className="mb-0 fs-5">(786) 819-8806</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-5">
                <i className="bi bi-envelope-fill fs-2 me-3"></i>
                <div>
                  <p className="mb-0 fw-bold fs-4">{t('contactSubTitle3')}</p>
                  <p className="mb-0 fs-5">admin@languageherollc.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-5">
                <i className="bi bi-clock-fill fs-2 me-3"></i>
                <div>
                  <p className="mb-0 fw-bold fs-4">{t('contactSubTitle4')}</p>
                  <p className="mb-0 fs-5">{t('contactSubTitle4a')}</p>
                  <p className="mb-0 fs-5">{t('contactSubTitle4b')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <form onSubmit={handleSubmit} className="p-4 rounded transparent-bg-contact mb-4">
              {i18n.language === 'en'? (
                <h1 className='font-reross h-color hTitle2 text-center'>CONTACT US</h1>
              ) : (
                <h1 className='font-reross h-color hTitle2 text-center'>CONTÁCTANOS</h1>
              )
            }
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label text-white">{t('contactTextForm1')}</label>
                <input
                  type="text"
                  id="nombre"
                  className="form-control"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="apellido" className="form-label text-white">{t('contactTextForm2')}</label>
                <input
                  type="email"
                  id="mail"
                  className="form-control"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="telefono" className="form-label text-white">{t('contactTextForm3')}</label>
                <input
                  type="tel"
                  id="telefono"
                  className="form-control"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="mensaje" className="form-label text-white">{t('contactTextForm4')}</label>
                <textarea
                  id="mensaje"
                  className="form-control"
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button type="submit" className="btn btn-primary">{t('contactSendBtn')}</button>
                {showMessage && <p className="text-white ms-3 mb-0">{message}</p>}
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
