import React, { useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '..//Reutilizables/Header/Header.jsx';
import Footer from '../Reutilizables/Footer/Footer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Location.css";

function Location() {
  
  useEffect(() => {
    document.body.scrollTop = 0; // Para Safari
    document.documentElement.scrollTop = 0; // Para Chrome, Firefox, IE y Opera
  }, []);
  
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='min-vh-100 bg-miami bg-miami-celu'>
      <Header changeLanguage={changeLanguage} i18n={i18n}/>
      <div className="d-flex flex-column min-vh-100">
        <div className='container text-white'>
          {i18n.language === 'en'? (
              <h1 className='font-reross hTitle'>LOCATION</h1>
            ) : (
              <h1 className='font-reross hTitle'>UBICACIÓN</h1>
            )
          }
          <div className="col-md-5 mt-5 p-2 rounded transparent-bg justify-content-start">
            <h2>{t('locationSubTitle')}</h2>
            <p className='fs-6'>
              {t('locationText')}
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Location;