import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home.jsx';
import Services from './components/Services/Services.jsx';
import Industries from './components/Industries/Industries.jsx';
import Location from './components/Location/Location.jsx';
import About from './components/About/About.jsx';
import Contact from './components/Contact/Contact.jsx';
import GetStarted from './components/GetStarted/GetStarted.jsx';

function App() {

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      document.body.classList.add('android');
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.body.classList.add('ios');
    }
  }, []);
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/location" element={<Location />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/getstarted" element={<GetStarted />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
