// src/components/Reutilizables/Header/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../LanguageProvider';
import logo from "../../../extras/logoLanguageHeroBlanco.png";
import nombreImg from "../../../extras/TituloNombre.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Header.css";

const Header = () => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  return (
    <div className='transparent-bg mb-5'>
      <header className="text-white">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={logo} alt="Logo" width="100" className="img-fluid p-2"/>
            </div>
            <div className="d-flex flex-grow-1 justify-content-center">
              <Link to='/'><img src={nombreImg} alt="" className='img-fluid titleNombre'/></Link>
            </div>
            <div className="d-flex align-items-center">
              <nav className="d-none d-md-block">
                <Link className="btn-header btn mx-1" to="/">{t('home')}</Link>
                <Link className="btn-header btn mx-1" to="/services">{t('services')}</Link>
                <Link className="btn-header btn mx-1" to="/industries">{t('industries')}</Link>
                <Link className="btn-header btn mx-1" to="/location">{t('location')}</Link>
                <Link className="btn-header btn mx-1" to="/about">{t('aboutUs')}</Link>
                <Link className="btn-header btn mx-1" to="/contact">{t('contact')}</Link>
                <Link className="header-getStarted btn mx-1" to="/getstarted">{t('getStarted')}</Link>
                {/* EN/ES */}
                <button
                  onClick={() => changeLanguage('en')}
                  className={`btn-header btn language-button ${language === 'en' ? 'active' : ''}`}
                >
                  EN
                </button>
                <button
                  onClick={() => changeLanguage('es')}
                  className={`btn-header btn language-button ${language === 'es' ? 'active' : ''}`}
                >
                  ES
                </button>
              </nav>
              <button className="d-block d-md-none btn btn-outline-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div className="collapse d-md-none" id="navbarToggleExternalContent">
            <nav className="d-flex flex-column align-items-end">
              <Link className="btn-header btn my-1" to="/">{t('home')}</Link>
              <Link className="btn-header btn my-1" to="/services">{t('services')}</Link>
              <Link className="btn-header btn my-1" to="/industries">{t('industries')}</Link>
              <Link className="btn-header btn my-1" to="/location">{t('location')}</Link>
              <Link className="btn-header btn my-1" to="/about">{t('aboutUs')}</Link>
              <Link className="btn-header btn my-1" to="/contact">{t('contact')}</Link>
              <Link className="header-getStarted btn mx-1" to="/getstarted">{t('getStarted')}</Link>
              {/* EN/ES */}
              <div className='d-flex mx-auto'>
                <button
                  onClick={() => changeLanguage('en')}
                  className={`btn-header btn my-1 language-button ${language === 'en' ? 'active' : ''}`}
                >
                  EN
                </button>
                <button
                  onClick={() => changeLanguage('es')}
                  className={`btn-header btn my-1 language-button ${language === 'es' ? 'active' : ''}`}
                >
                  ES
                </button>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
