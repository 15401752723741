import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../LanguageProvider';
import Header from '../../components/Reutilizables/Header/Header';
import Footer from '../../components/Reutilizables/Footer/Footer';
import box1 from "../../extras/BOX3.png";
import box2 from "../../extras/BOX4.png";
import box3 from "../../extras/BOX2.png";
import box4 from "../../extras/BOX1.png";
import asocImg from "../../extras/atifAssoc.png";
import atiImg from "../../extras/ATA.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";

function Home() {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <div className="d-flex flex-column min-vh-100 bg-celular">
      <Header />
      <main className="flex-grow-1 text-left text-white">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-md-7">
              {language === 'en' ? (
                <h1 className='font-reross hSubTitle'>YOUR SUPERPOWER</h1>
              ) : (
                <h1 className='font-reross hSubTitle'>TU SUPERPODER</h1>
              )}
              <h2>{t('subTitleHome')}</h2>
              <p>{t('textHome')}</p>
              <Link className="btn-getStarted btn mt-3" to="/getstarted">{t('getStarted')}</Link>
            </div>
          </div>
          <h1 className='font-reross h-color title-center hTitle titleHome'>LANGUAGE HERO</h1>
          <div className="row mb-5 box-container">
            <div className="col-12 col-md-3 mt-5">
              <div className="service-box">
                <h4>{t('aboutUs')}</h4>
                <img src={box1} alt="" className='img-fluid boxImg'/>
                <Link className="btn-box btn" to="/about">{t('ReadMore')}</Link>
              </div>
            </div>
            <div className="col-12 col-md-3 mt-5">
              <div className="service-box">
                <h4>{t('location')}</h4>
                <img src={box2} alt="" className='img-fluid boxImg'/>
                <Link className="btn-box btn" to="/location">{t('ReadMore')}</Link>
              </div>
            </div>
            <div className="col-12 col-md-3 mt-5">
              <div className="service-box">
                <h4>{t('industries')}</h4>
                <img src={box3} alt="" className='img-fluid boxImg'/>
                <Link className="btn-box btn" to="/industries">{t('ReadMore')}</Link>
              </div>
            </div>
            <div className="col-12 col-md-3 mt-5">
              <div className="service-box">
                <h4>{t('services')}</h4>
                <img src={box4} alt="" className='img-fluid p-2'/>
                <Link className="btn-box btn" to="/services">{t('ReadMore')}</Link>
              </div>
            </div>
          </div>
          <div className='div-licencias d-flex justify-content-center'>
            <img src={atiImg} alt="" className='img-fluid p-2 bg-white rounded img-licencias'/>
            <img src={asocImg} alt="" className='img-fluid p-2 bg-white rounded img-licencias'/>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;