import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Header from '../Reutilizables/Header/Header.jsx';
import Footer from '../Reutilizables/Footer/Footer.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./GetStarted.css";

function GetStarted() {
  
  useEffect(() => {
    document.body.scrollTop = 0; // Para Safari
    document.documentElement.scrollTop = 0; // Para Chrome, Firefox, IE y Opera
  }, []);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [nombre, setNombre] = useState('');
  const [mail, setMail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('https://us-central1-languagehero-32080.cloudfunctions.net/api/getstarted', {
        nombre,
        mail,
        telefono,
        mensaje,
        service,
      });
      if (response.status === 200) {
        setMessage('Email enviado con éxito!');
        setNombre('');
        setMail('');
        setTelefono('');
        setMensaje('');
        setService('');
      } else {
        setMessage('Error al enviar el email');
      }
    } catch (error) {
      console.error('Error al enviar el email:', error.response ? error.response.data : error.message);
      setMessage('Error al enviar el email');
    }
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };  

  return (
    <div className="d-flex flex-column min-vh-100 bg-celular">
      <Header changeLanguage={changeLanguage} i18n={i18n}/>
      <div className="flex-grow-1 container">
        {i18n.language === 'en'? (
                <h1 className='font-reross h-color hTitle2 mb-5'>GET STARTED</h1>
              ) : (
                <h1 className='font-reross h-color hTitle2 mb-5'>COMIENCE AQUÍ</h1>
              )
            }
        <form onSubmit={handleSubmit} className="form-getStarted p-4 rounded transparent-bg-contact">
          <div className="mb-3">
            <label htmlFor="nombre" className="form-label text-white">{t('getStartedText1')}</label>
            <input
              type="text"
              id="nombre"
              className="form-control"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="mail" className="form-label text-white">{t('getStartedText2')}</label>
            <input
              type="email"
              id="mail"
              className="form-control"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="telefono" className="form-label text-white">{t('getStartedText3')}</label>
            <input
              type="tel"
              id="telefono"
              className="form-control"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              required
            />
          </div>
          
          <div className="mb-3">
            <label htmlFor="service" className="form-label text-white">{t('getStartedText4')}</label>
            <select
              id="service"
              className="form-select"
              value={service}
              onChange={(e) => setService(e.target.value)}
              required
            >
              <option value="" disabled>{t('getStartedText4a')}</option>
              <option value="translation">{t('getStartedService0')}</option>
              <option value="simultaneous">{t('getStartedService1')}</option>
              <option value="consecutive">{t('getStartedService2')}</option>
              <option value="sight">{t('getStartedService3')}</option>
              <option value="chuchotage">{t('getStartedService4')}</option>
              <option value="equipment">{t('getStartedService5')}</option>
            </select>
          </div>
          
          <div className="mb-3">
            <label htmlFor="mensaje" className="form-label text-white">{t('getStartedText5')}</label>
            <textarea
              id="mensaje"
              className="form-control"
              value={mensaje}
              onChange={(e) => setMensaje(e.target.value)}
              required
            />
          </div>
          
          <div className="d-flex justify-content-center align-items-center">
            <button type="submit" className="btn btn-primary">{t('getStartedBtn')}</button>
            {showMessage && <p className="text-white ms-3 mb-0">{message}</p>}
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  );
}

export default GetStarted;
