import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '..//Reutilizables/Header/Header.jsx';
import Footer from '../Reutilizables/Footer/Footer.jsx';
import ContactImg1 from "../../extras/ContactImg4.jpeg"
import ContactImg2 from "../../extras/ContactImg5.jpeg"
import ContactImg3 from "../../extras/ContactImg6.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./About.css";

function About() {

  useEffect(() => {
    document.body.scrollTop = 0; // Para Safari
    document.documentElement.scrollTop = 0; // Para Chrome, Firefox, IE y Opera
  }, []);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
      <div className="d-flex flex-column min-vh-100 bg-celular">
        <Header changeLanguage={changeLanguage} i18n={i18n}/>
        <div className='container flex-grow-1 text-white'>
          {i18n.language === 'en'? (
              <h1 className='font-reross h-color hTitle2'>ABOUT US</h1>
            ) : (
              <h1 className='font-reross h-color hTitle2'>QUIÉNES SOMOS</h1>
            )
          }
          <div className="col-md-6 mt-5 justify-content-start">
            <h4>
            {t('aboutSubTitle1')}
            </h4>
            <h4>
            {t('aboutSubTitle2')}
            </h4>
            <p>
            {t('aboutText1')}
            </p>
            <p>
            {t('aboutText2')}
            </p>
          </div>
          <div className="row justify-content-around">
            <div className="col-md-4 mt-5">
              <div className="service-box">
                <img src={ContactImg1} alt="" className='img-fluid'/>
                <h4 className="text-white mb-0">Mariana G. Tarifa</h4>
                <h5 className='mt-2'>{t('aboutBoxText1')}</h5>
                <h5>{t('aboutBoxText2')}</h5>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box">
                <img src={ContactImg2} alt="" className='img-fluid '/>
                <h4 className="text-white mb-0">Julian Sieser</h4>
                <h5 className='mt-2'>{t('aboutBoxText3')}</h5>
                <h5>
                  {t('aboutBoxText4')}
                  <a href="https://gotitllc.com/" target="_blank" rel="noopener noreferrer" className='text-decoration-none'>Gotitllc.com</a>
                </h5>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box">
                <img src={ContactImg3} alt="" className='img-fluid'/>
                <div className='col d-flex align-items-center'>
                  <h4 className="text-white mb-0 me-2">Paulette Pagani</h4>
                  <a href="https://www.linkedin.com/in/paulette-pagani-963a89263/" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-linkedin fs-2"></i>
                  </a>
                </div>
                <h5 className='mt-2'>{t('aboutBoxText5')}</h5>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  );
}

export default About;