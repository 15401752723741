import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Reutilizables/Header/Header';
import Footer from '../Reutilizables/Footer/Footer';
import industryImg1 from "../../extras/educational.jpeg";
import industryImg2 from "../../extras/medical.jpeg";
import industryImg3 from "../../extras/legal.jpeg";
import industryImg4 from "../../extras/business.jpeg";
import industryImg5 from "../../extras/government.jpeg";
import industryImg6 from "../../extras/technical.jpeg";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Industries.css";

function Industries() {
  
  useEffect(() => {
    document.body.scrollTop = 0; // Para Safari
    document.documentElement.scrollTop = 0; // Para Chrome, Firefox, IE y Opera
  }, []);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
      <div className="d-flex flex-column min-vh-100 bg-celular">
        <Header changeLanguage={changeLanguage} i18n={i18n}/>
        <div className='container flex-grow-1 text-white'>
          {i18n.language === 'en'? (
              <h1 className='font-reross h-color hTitle2'>INDUSTRIES WE SERVE</h1>
            ) : (
              <h1 className='font-reross h-color hSubTitle'>INDUSTRIAS A LAS QUE PRESTAMOS SERVICIOS</h1>
            )
          }
          <div className="row justify-content-around">
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg2} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle1')}</h4>
                <p>{t('industriesText1')}</p>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg1} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle2')}</h4>
                <p>{t('industriesText2')}</p>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg3} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle3')}</h4>
                <p>{t('industriesText3')}</p>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg4} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle4')}</h4>
                <p>{t('industriesText4')}</p>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg5} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle5')}</h4>
                <p>{t('industriesText5')}</p>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="service-box text-center">
                <img src={industryImg6} alt="" className='img-fluid'/>
                <h4 className="text-white">{t('industriesTextTitle6')}</h4>
                <p>{t('industriesText6')}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  );
}

export default Industries;
